@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|PT+Sans:400,700|Rozha+One');
$textFont: 'Open Sans', sans-serif;
$displayFont: 'Rozha One', serif;

$titleColor:#A09EA0;
$paraghColor:#B0AEB0;
$greyBG: #F7F7F7;
$footerBG:#141A23;
$black:#202020;
$red:#86191D;
$green:#586B25;

*{
  box-sizing: border-box;
}
body{
  font-family: $textFont;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  color: $black;
}

h2{
  font-family: $displayFont;
  font-size: 3rem;
  margin: 0.5rem 0 3rem;
  text-transform: capitalize;
  color: $black;
}

h5{
  color: $titleColor;
  font-size: 0.7rem;
  margin: 0.5rem 0 1rem;
  text-transform: uppercase;
  letter-spacing: 5px;
}

p{
  font-size: 0.8rem;
  color: $paraghColor;
  line-height: 2rem;
  margin: 0.5rem 0 3rem;
  font-weight: 300;
}



strong{
  font-weight: 600;
}

.comp-title{
  font-size: 1rem;
}

.texturebg{
  // background: $greyBG;
  background-image: url('./img/texture.png');
  background-position: center;
  // background-size: cover;
  background-repeat: repeat;
}

.logo{
  display: inline-block;
  padding: 10px 10px;
  color: white;
}

#Header{
  // width: 100%;
  // z-index: 20;
  nav{
    // float: right;
    .headerMenu{
      // display: inline-block;
      transition: all .3s ease-in-out;
      color: white;
      text-decoration: none;
      padding: 20px 10px;
      cursor: pointer;
      &:hover{
        color: $red;
      }
    }
  }
  .navbar-toggler{
    background-color: $greyBG;
  }
}

#TopImg{
  // min-height: 500px;
  height: 100vh;
  position: relative;
  // background: #b51212;
  background: linear-gradient(135deg,rgba(0,0,0,.2) 0,#002d77 100%);
  background-image: linear-gradient(135deg,rgba(0,0,0,.2) 0,#000 100%),url('./img/1.jpg');
  background-position: center;
  background-size: cover;
  .title{
    padding: 120px 0 50px 0;
    text-align: right;
    color: white;
    font-weight: 300;
    text-transform: uppercase;
    h5{
      // font-size: 1rem;
      // font-size:2vw;
      font-size: calc(0.5em + 0.8vw);
    }
    h1{
      font-family: $displayFont;
      // font-size: 6rem;
      // font-size:5vw;
      font-size: calc(1.2em + 4.3vw);
    }
  }
  .contact-info{
    color: white;
    text-align: right;
    bottom: 0;
    line-height: 1.5rem;
    color: $paraghColor;
    strong{
      front-weight: bold;
      color: white;
    }
    .booking{
      margin-bottom: 10px;
      color: $paraghColor;
      font-size: calc(0.8em + 0.2vw);
    }
    .phonenumber{
      font-size: calc(2em + 1vw);
      margin-bottom: 20px;
      color: white;
    }
    .hours{
      font-size: calc(0.8em + 0.2vw);
    }
  }
}

#OurStory{
  padding: 100px 0;
  .quote{
    color: $paraghColor;
    margin: 0.3rem 0 2.5rem;
    strong{
      color:$black;
      font-weight: 700;
      font-size: 1.1rem;
    }
  }
  .reserve-btn{
    font-family: $displayFont;
    display: inline-block;
    padding: 20px 40px;
    border: 1px solid $black;
    text-align: center;
    text-decoration: none;
    color: $black;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
    letter-spacing: 1px;
    margin-bottom: 10px;
    &:hover{
        background: $red;
        color: white;
        cursor: pointer;
    }
  }
  .video-image{
    max-width: 400px;
    height: 600px;
    // background: linear-gradient(135deg,rgba(0,0,0,.1) 0,#002d77 100%);
    // background-image: linear-gradient(135deg,rgba(0,0,0,.1) 0,#000 100%),url('./img/2.jpg');
    background-image: url('./img/2.jpg');
    background-position: right;
    background-size: cover;
    margin: 0 auto;
  }
}

#SpecialMenu{
  padding: 100px 0;
  h2, h5{
    text-align: center;
  }
  .box{
    background: white;
    padding: 20px;
    display: block;
    .box-img{
      position: relative;
      width: 100%;
      height: 200px;
      // background: linear-gradient(135deg,rgba(0,0,0,.2) 0,#002d77 100%);
      // background-image: linear-gradient(135deg,rgba(0,0,0,.2) 0,#000 100%),url('https://cdn.gearpatrol.com/wp-content/uploads/2018/10/The-20-Best-Things-to-Cook-Cast-Iron-Skillet-Gear-Patrol-Okra-Tomatoes.jpg');
      background-position: center;
      background-size: cover;
      margin-bottom: 70px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .price-circle{
      position: absolute;
      box-sizing: border-box;
      bottom: 0;
      margin: 0 auto;
      font-family: $displayFont;
      font-size: 2.5rem;
      background: #fff;
      height: 100px;
      width: 100px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 35%;
      transform: translateY(50%);
      box-shadow: 0px 0px 0px 10px rgba(255,255,255,0.41);
    }
    .title{
      font-weight: 600;
      font-size: 0.9rem;
      text-align: center;
    }
    .details{
      font-weight: 300;
      font-size: 0.8rem;
      text-align: center;
      line-height: 2;
      margin: 0;
    }
  }
  .boxes{
    padding-bottom: 50px
  }
  // a.link{
  //   color: black;
  //   font-family: $displayFont;
  //   text-align: center;
  //   display: block;
  // }
  // a.link:hover{
  //   text-decoration: none;
  //   color: $red;
  //   font-weight: 500;
  // }
  .reserve-btn{
    font-family: $displayFont;
    display: inline-block;
    padding: 20px 40px;
    border: 1px solid $black;
    text-align: center;
    text-decoration: none;
    color: $black;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
    &:hover{
        background: $red;
        color: white;
    }
  }
}

#RandomQuote{
  padding: 180px 0;
  background: linear-gradient(135deg,rgba(0,0,0,.2) 0,#002d77 100%);
  background-position: right;
  background-size: cover;
  h1{
    color: white;
    font-family: $displayFont;
    font-size: 3rem;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 50px;
    line-height: 1.3;
  }
  span{
    color: white;
    font-size: 1.5rem;
    font-weight: 300;
    text-transform: capitalize;
    text-align: center;
    display: block;
  }
}

#Reviews{
  padding: 100px 0;
  text-align: right;
  .side-img{
    // position: relative;
    // height: 200px;
    // width: 400px;
    text-align: left;
    img{
      // position: absolute;
      // max-width: 600px;
      width: 100%;
    }
  }
  h4{
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }
  .author{
    font-size: 0.8rem;
    color: $paraghColor;
    font-weight: 300;
    text-transform: capitalize;
    margin-bottom: 2rem;
    strong{
      font-weight: 700;
      color: $black;
    }
  }
  .arrows{
    .fa-arrow-left, .fa-arrow-right{
      font-size: 1.5rem;
      padding: 20px 10px;
      color: $paraghColor;
      cursor: pointer;
    }
    .fa-arrow-left.ready, .fa-arrow-right.ready{
      color: $black;
    }
  }
}

#ContactUs{
 padding: 100px 0;
 h2, h5{
   text-align: center;
 }
 .box{
   background: white;
   padding: 70px;
   .title{
     font-family: $displayFont;
     font-size: 2rem;
     margin-bottom: 1.4rem;
   }
   h6{
     font-size: 0.9rem;
     font-weight: 500;
     color: $titleColor;
     line-height: 1.7;
     color: $black;
   }
   p{
     font-weight: 400;
     margin-bottom: 25px;
     font-size: 0.9rem;
   }
   a{
     color: $black;
     text-decoration: none;
     font-size: 1.5rem;
     &:hover{
       color: $red;
     }
   }
 }
}

#Footer{
  padding: 80px 0;
  background: $footerBG;
  width: 100%;
  text-align: center;
  nav{
    display: inline-block;
    margin: 0 auto 50px;
    .footerMenu{
      color: white;
      display: inline-block;
      padding: 10px 20px 10px 0;
      text-decoration: none;
      transition: all .3s ease-in-out;
      cursor: pointer;
      &:hover{
        color: $red;
      }
    }
  }
  .social-media{
    margin: 0 0 50px;
    padding: 0;
    li{
      display: inline-block;
      padding: 20px;
      a{
        color: white;
        transition: all .3s ease-in-out;
        &:hover{
          color: $red;
        }
        i{
          font-size: 2rem;
        }
      }
    }
  }
  .footerlink{
    color: white;
  }
  .footerlink a{
    color: lightblue;
  }
}
