@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|PT+Sans:400,700|Rozha+One);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box; }

body {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  color: #202020; }

h2 {
  font-family: "Rozha One", serif;
  font-size: 3rem;
  margin: 0.5rem 0 3rem;
  text-transform: capitalize;
  color: #202020; }

h5 {
  color: #A09EA0;
  font-size: 0.7rem;
  margin: 0.5rem 0 1rem;
  text-transform: uppercase;
  letter-spacing: 5px; }

p {
  font-size: 0.8rem;
  color: #B0AEB0;
  line-height: 2rem;
  margin: 0.5rem 0 3rem;
  font-weight: 300; }

strong {
  font-weight: 600; }

.comp-title {
  font-size: 1rem; }

.texturebg {
  background-image: url(/static/media/texture.3d04ecfc.png);
  background-position: center;
  background-repeat: repeat; }

.logo {
  display: inline-block;
  padding: 10px 10px;
  color: white; }

#Header nav .headerMenu {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  color: white;
  text-decoration: none;
  padding: 20px 10px;
  cursor: pointer; }
  #Header nav .headerMenu:hover {
    color: #86191D; }

#Header .navbar-toggler {
  background-color: #F7F7F7; }

#TopImg {
  height: 100vh;
  position: relative;
  background: -webkit-linear-gradient(315deg, rgba(0, 0, 0, 0.2) 0, #002d77 100%);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.2) 0, #002d77 100%);
  background-image: -webkit-linear-gradient(315deg, rgba(0, 0, 0, 0.2) 0, #000 100%), url(/static/media/1.acf0653e.jpg);
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.2) 0, #000 100%), url(/static/media/1.acf0653e.jpg);
  background-position: center;
  background-size: cover; }
  #TopImg .title {
    padding: 120px 0 50px 0;
    text-align: right;
    color: white;
    font-weight: 300;
    text-transform: uppercase; }
    #TopImg .title h5 {
      font-size: calc(0.5em + 0.8vw); }
    #TopImg .title h1 {
      font-family: "Rozha One", serif;
      font-size: calc(1.2em + 4.3vw); }
  #TopImg .contact-info {
    color: white;
    text-align: right;
    bottom: 0;
    line-height: 1.5rem;
    color: #B0AEB0; }
    #TopImg .contact-info strong {
      front-weight: bold;
      color: white; }
    #TopImg .contact-info .booking {
      margin-bottom: 10px;
      color: #B0AEB0;
      font-size: calc(0.8em + 0.2vw); }
    #TopImg .contact-info .phonenumber {
      font-size: calc(2em + 1vw);
      margin-bottom: 20px;
      color: white; }
    #TopImg .contact-info .hours {
      font-size: calc(0.8em + 0.2vw); }

#OurStory {
  padding: 100px 0; }
  #OurStory .quote {
    color: #B0AEB0;
    margin: 0.3rem 0 2.5rem; }
    #OurStory .quote strong {
      color: #202020;
      font-weight: 700;
      font-size: 1.1rem; }
  #OurStory .reserve-btn {
    font-family: "Rozha One", serif;
    display: inline-block;
    padding: 20px 40px;
    border: 1px solid #202020;
    text-align: center;
    text-decoration: none;
    color: #202020;
    text-transform: uppercase;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    letter-spacing: 1px;
    margin-bottom: 10px; }
    #OurStory .reserve-btn:hover {
      background: #86191D;
      color: white;
      cursor: pointer; }
  #OurStory .video-image {
    max-width: 400px;
    height: 600px;
    background-image: url(/static/media/2.6be47c8f.jpg);
    background-position: right;
    background-size: cover;
    margin: 0 auto; }

#SpecialMenu {
  padding: 100px 0; }
  #SpecialMenu h2, #SpecialMenu h5 {
    text-align: center; }
  #SpecialMenu .box {
    background: white;
    padding: 20px;
    display: block; }
    #SpecialMenu .box .box-img {
      position: relative;
      width: 100%;
      height: 200px;
      background-position: center;
      background-size: cover;
      margin-bottom: 70px; }
      #SpecialMenu .box .box-img img {
        width: 100%;
        height: 100%; }
    #SpecialMenu .box .price-circle {
      position: absolute;
      box-sizing: border-box;
      bottom: 0;
      margin: 0 auto;
      font-family: "Rozha One", serif;
      font-size: 2.5rem;
      background: #fff;
      height: 100px;
      width: 100px;
      border-radius: 50%;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      left: 35%;
      -webkit-transform: translateY(50%);
              transform: translateY(50%);
      box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 0.41); }
    #SpecialMenu .box .title {
      font-weight: 600;
      font-size: 0.9rem;
      text-align: center; }
    #SpecialMenu .box .details {
      font-weight: 300;
      font-size: 0.8rem;
      text-align: center;
      line-height: 2;
      margin: 0; }
  #SpecialMenu .boxes {
    padding-bottom: 50px; }
  #SpecialMenu .reserve-btn {
    font-family: "Rozha One", serif;
    display: inline-block;
    padding: 20px 40px;
    border: 1px solid #202020;
    text-align: center;
    text-decoration: none;
    color: #202020;
    text-transform: uppercase;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out; }
    #SpecialMenu .reserve-btn:hover {
      background: #86191D;
      color: white; }

#RandomQuote {
  padding: 180px 0;
  background: -webkit-linear-gradient(315deg, rgba(0, 0, 0, 0.2) 0, #002d77 100%);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.2) 0, #002d77 100%);
  background-position: right;
  background-size: cover; }
  #RandomQuote h1 {
    color: white;
    font-family: "Rozha One", serif;
    font-size: 3rem;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 50px;
    line-height: 1.3; }
  #RandomQuote span {
    color: white;
    font-size: 1.5rem;
    font-weight: 300;
    text-transform: capitalize;
    text-align: center;
    display: block; }

#Reviews {
  padding: 100px 0;
  text-align: right; }
  #Reviews .side-img {
    text-align: left; }
    #Reviews .side-img img {
      width: 100%; }
  #Reviews h4 {
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 2rem; }
  #Reviews .author {
    font-size: 0.8rem;
    color: #B0AEB0;
    font-weight: 300;
    text-transform: capitalize;
    margin-bottom: 2rem; }
    #Reviews .author strong {
      font-weight: 700;
      color: #202020; }
  #Reviews .arrows .fa-arrow-left, #Reviews .arrows .fa-arrow-right {
    font-size: 1.5rem;
    padding: 20px 10px;
    color: #B0AEB0;
    cursor: pointer; }
  #Reviews .arrows .fa-arrow-left.ready, #Reviews .arrows .fa-arrow-right.ready {
    color: #202020; }

#ContactUs {
  padding: 100px 0; }
  #ContactUs h2, #ContactUs h5 {
    text-align: center; }
  #ContactUs .box {
    background: white;
    padding: 70px; }
    #ContactUs .box .title {
      font-family: "Rozha One", serif;
      font-size: 2rem;
      margin-bottom: 1.4rem; }
    #ContactUs .box h6 {
      font-size: 0.9rem;
      font-weight: 500;
      color: #A09EA0;
      line-height: 1.7;
      color: #202020; }
    #ContactUs .box p {
      font-weight: 400;
      margin-bottom: 25px;
      font-size: 0.9rem; }
    #ContactUs .box a {
      color: #202020;
      text-decoration: none;
      font-size: 1.5rem; }
      #ContactUs .box a:hover {
        color: #86191D; }

#Footer {
  padding: 80px 0;
  background: #141A23;
  width: 100%;
  text-align: center; }
  #Footer nav {
    display: inline-block;
    margin: 0 auto 50px; }
    #Footer nav .footerMenu {
      color: white;
      display: inline-block;
      padding: 10px 20px 10px 0;
      text-decoration: none;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      cursor: pointer; }
      #Footer nav .footerMenu:hover {
        color: #86191D; }
  #Footer .social-media {
    margin: 0 0 50px;
    padding: 0; }
    #Footer .social-media li {
      display: inline-block;
      padding: 20px; }
      #Footer .social-media li a {
        color: white;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out; }
        #Footer .social-media li a:hover {
          color: #86191D; }
        #Footer .social-media li a i {
          font-size: 2rem; }
  #Footer .footerlink {
    color: white; }
  #Footer .footerlink a {
    color: lightblue; }

